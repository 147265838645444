<template>
    <Breadcrumb page-title="Notification Alerts" page-section="Alerts"> </Breadcrumb>
     <section class="content">
        <div class="row">
                 <div class="col-md-12">
                   <div class="box box-info">
                     <div class="box-header with-border">
                       <h3 class="box-title head-title-padding">Error and Success Messages</h3>
                     </div>
                     <!-- /.box-header -->
                     <!-- form start -->
                     
                     <div class="box-body table-responsive">
                        <div class="col-md-12 mbottom-15 form-alert-message">
                            <div class="alert alert-danger alert-dismissible">
                                <button type="button" class="close">×</button>
                                <div class="message-container">
                                <div class="icon">
                                    <i class="icon fa fa-ban"></i>
                                </div>
                                <div class="message">
                                    <strong>Agent Email</strong>
                                    <ul>
                                    <li> Please enter a subject for your note.</li>
                                    </ul>
                                </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-12 mbottom-15 form-alert-message">
                            <div class="alert alert-success alert-dismissible">
                                <button type="button" class="close">×</button>
                                <div class="message-container">
                                <div class="icon">
                                    <i class="icon fa fa-ban"></i>
                                </div>
                                <div class="message">
                                    <strong>Agent Email</strong>
                                    <ul>
                                    <li> Please enter a subject for your note.</li>
                                    </ul>
                                </div>
                                </div>
                            </div>
                        </div>

                        <div class="col-md-12 mbottom-15">
                            <div class="alert alert-warning alert-dismissible alert-inline pull-left mbottom-15">
                                <button type="button" class="close" v-on:click="alertmessage = false;">&times;</button>    
                                <h4><i class="icon fa fa-warning"></i>Alert! <span class="text">You have reached the maximum number of interactions that can be displayed on this page (2500).<span class="more" v-on:click="showmore = !showmore" v-show="!showmore">...more</span><span class="more"  v-on:click="showmore = !showmore" v-show="showmore" >...less</span></span></h4>
                                <div class="mtop-15" v-show =" showmore">
                                    This page displays a maximum of 2500 unresolved questions to avoid performance issues caused by loading details of too many interactions. Since you have more than 2500 unresolved questions, some of your questions are not displayed. Answer or resolve enough questions to keep your total unresolved questions below 2500.
                                </div>	
                            </div>	
                        </div>
                       
                     </div>
                   </div>
                 </div>
                 <div class="col-md-4">
                 </div>
               </div>
     </section>
   </template>
   
   <script>
   // @ is an alias to /src
   import Breadcrumb from '../components/Breadcrumb.vue';
   import NewAccountInformation from '../components/NewAccountInformation.vue';
   
   export default {
     name: 'Notification Alert',
     components: {
       Breadcrumb,
       NewAccountInformation
     },
     data() {
      return{
          showmore : false,
         
      }
  },
     ready () {
       $(document).ready(function ($) {
       })
     }
   }
   </script>
   